import React, { useState } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

const AboutUSMain = ({
  fontSize,
  title,
  bread,
  buttontext,
  onClick,
  src,
  cover,
  position,
  translateY,
  marginLeft,
}) => {
  return (
    <>
      <div
        className="about-us-second-section"
        style={{
          background: ` linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${src})`,
          backgroundSize: `${cover}`,
          backgroundPosition: `${position}`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="content_container">
          <p className={fontSize ? "team-about-us-title" : "about-us-title"}>
            {title}
          </p>
          <div className="row-breadcrumb-row">
            {bread && (
              <nav aria-label="breadcrumb">
                <ol
                  className="breadcrumb"
                  style={{
                    transform: `translateY(-${translateY})`,
                    marginLeft: `${marginLeft}`,
                  }}
                >
                  <li>
                    <FontAwesomeIcon
                      className="icon mr-2 active"
                      icon={faHome}
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">HOME</Link>
                  </li>
                  <li className=" active" aria-current="page">
                    {bread}
                  </li>
                </ol>
              </nav>
            )}
          </div>
          {buttontext && (
            <Button className="about-us-main-button" onClick={onClick}>
              {buttontext}
            </Button>
          )}
        </div>

        {/* <div className="row-breadcrumb-row">
          {bread && (
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb"
                style={{
                  transform: `translateY(-${translateY})`,
                  marginLeft: `${marginLeft}`,
                }}
              >
                <li>
                  <FontAwesomeIcon className="icon mr-2 active" icon={faHome} />
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">HOME</Link>
                </li>
                <li className=" active" aria-current="page">
                  {bread}
                </li>
              </ol>
            </nav>
          )}
        </div> */}
      </div>
    </>
  );
};

export default AboutUSMain;
