import React, { useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import OffMarketListings from "../components/offmarketlistings/off-market-listings-main";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";

const AboutUs = ({ data }) => {
  const properties = data.allSanityProperty.nodes;
  const seo = data.sanitySeo;
  const banner = data.sanityBanners.banner.asset.url;
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            title="Off Market Listings"
            bread="Off Market Listings"
            position="50%"
            buttontext=""
            src={banner}
            cover="cover"
          />
          <div
            style={{
              backgroundColor: "#f7f7f7",
            }}
          >
            <div ref={ref}>
              <OffMarketListings properties={properties} />
            </div>

            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default AboutUs;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-a80a4421-c67e-50e9-b4e8-55cf28baf177" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    allSanityProperty(filter: { offMarket: { eq: true } }) {
      nodes {
        propertyname
        propertylocation
        slug {
          current
        }
        propertyprice
        propertystatus
        propertyimage {
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              placeholder: DOMINANT_COLOR
              fit: MAX
              width: 420
            )
          }
        }
        propertyarea
        propertybedrooms
        propertybathrooms
      }
    }
    sanitySeo(pagehandle: { eq: "off-market-listings" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
