import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
const SecondVideoSection = () => {
  return (
    <>
      <div style={{ backgroundColor: "#086333" }}>
        <div
          style={{
            height: "150px",
            backgroundColor: "#086333",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "row",
          }}
          className="p-5 video-mail-section container-sm "
        >
          <p style={{ fontSize: "32px", color: "white" }}>
            SIGN UP FOR NEWSLETTER
          </p>
          <form
            id="mc4wp-form-1"
            className="mc4wp-form mc4wp-form-2534 "
            method="post"
            data-id="2534"
            data-name="Mailchimp"
          >
            <div className="mc4wp-form-fields">
              <div
                className="g5plus-mailchimp"
                style={{
                  width: "570px",
                  height: "50px",
                  borderRadius: "30px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <input
                  style={{
                    borderRadius: "30px",
                    width: "92%",
                    height: "100%",
                    border: "0",
                  }}
                  className="p-3 form-control"
                  type="email"
                  name="EMAIL"
                  placeholder="E-mail Address"
                  required
                ></input>
                <FontAwesomeIcon
                  style={{ width: "24px", height: "30px", color: "#076332" }}
                  icon={faPaperPlane}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SecondVideoSection;
