import React, { useState } from "react";
import Card from "./off-market-card";
import MailSection from "../aboutus/about-us-mail-section";

import Img1 from "../../images/off-market-img-1.jpg";
import Img2 from "../../images/off-market-img-2.jpg";

const OffMarketListings = ({ properties }) => {
  const [state, setState] = useState("");
  const [value, setValue] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state, "state");
    if (state === "pamelahudson") {
      setValue(true);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div
        className="container w-100 pb-5 auth-class-main"
        style={{ height: `${!value ? "133px" : "auto"}` }}
      >
        {!value && (
          <div
            className="container-fluid w-100 auth-class"
            style={{ backgroundColor: "#f6f6f6", height: "133px" }}
          >
            <p className="off-market-listings-imput-text">
              This content is password protected. To view it please enter your
              password below:
            </p>
            <p className="off-market-listings-label-main">
              <form onSubmit={handleSubmit}>
                <label className="off-market-listings-label">Password: </label>
                <input
                  className="off-market-listings-input"
                  name="post_password"
                  id="pwbox-3792"
                  type="password"
                  size="20"
                  onChange={(e) => {
                    setError(false);
                    setState(e.target.value);
                  }}
                ></input>
                <input
                  className="off-market-listings-submit"
                  type="submit"
                  name="Submit"
                  value="Enter"
                ></input>
              </form>
            </p>
          </div>
        )}
        {error && (
          <div style={{ textAlign: "center" }}>
            <p style={{ color: "red" }}>Please enter the correct password!</p>
          </div>
        )}
        <div className="row off-market-listings-main w-100 mt-1 ml-5 mr-5 pt-5">
          {value && (
            <div className="col-12">
              <p className="off-market-listings-main-heading">
                Exclusive Properties
              </p>
            </div>
          )}
        </div>
        <div className="row w-100 pl-0 pr-0">
          {value && (
            <div className="col-11 ml-5 mr-5 pt-2">
              <p className="off-market-listings-main-text  pl-3 w-100">
                Off-market listings are also known as pocket listings. Our
                office specializes in facilitating this process for qualified
                properties. This limits traffic and exposure to non-qualified
                buyers.
              </p>
            </div>
          )}
        </div>
        <div className="row ml-5 mt-5">
          {value &&
            properties.map((property, index) => (
              <Card key={index} property={property} />
            ))}
        </div>
      </div>
      <div>{value && <MailSection />}</div>
    </>
  );
};

export default OffMarketListings;
