import React from "react";
import { Card, CardImg, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faBath, faBed } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const CardComponent = ({ property }) => {
  return (
    <div className="col-sm-12 col-md-12 col-lg-4 px-2">
      <Card className="Sold-section-card b-0">
        <Link className="p-4" to={`/property/${property.slug.current}`}>
          <GatsbyImage
            className="w-100 h-100 p-0 m-0 sold-section-image"
            width="100%"
            height="140%"
            objectFit="cover"
            style={
              {
                // maxWidth: "440px",
                // maxHeight: "440px",
              }
            }
            loading="lazy"
            image={property.propertyimage.asset.gatsbyImageData}
            alt={property.propertyname}
          />
        </Link>
        <CardBody className="p-3">
          <CardTitle className="sold-card-title" tag="h5">
            {property.propertyname}
          </CardTitle>
          <CardSubtitle className="sold-card-sub-text mb-3">
            {property.propertyname} {property.propertylocation}
          </CardSubtitle>
          <div className="row mt-3">
            <div className="col-6 mb-2" style={{ fontSize: "12px" }}>
              <FontAwesomeIcon
                className="mr-3"
                style={{ color: "#076332" }}
                icon={faChartArea}
              />
              {property.propertyarea} sqft
            </div>
            <div className="col-6 mb-2" style={{ fontSize: "12px" }}>
              <FontAwesomeIcon
                className="mr-3"
                style={{ color: "#076332" }}
                icon={faBed}
              />
              {property.propertybedrooms} Bedrooms
            </div>
            <div className="col-6 mb-2" style={{ fontSize: "12px" }}>
              <FontAwesomeIcon
                className="mr-3"
                style={{ color: "#076332" }}
                icon={faBath}
              />
              {property.propertybathrooms} Bathrooms
            </div>
          </div>
        </CardBody>
        <div
          className="property-status-price row"
          style={{
            marginTop: "0px",
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <div className="property-status col">
            <p className="item-status">{property.propertystatus}</p>
          </div>
          <div className="property-price col">
            $
            {property.propertyprice
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardComponent;
